<template>
	<div
		id="PageIntakePeriod"
		class="SMTPage"
	>
		<div class="SMTPageContentBlock">
			<h1 class="SMTTitle">
				Intake period
			</h1>
			<p class="SMTText">
				Select the intake period of the enrolments to verify.
			</p>
			<MultiSelect
				ref="intakeMonthMultiSelect"
				class="IntakeMultiSelect"
				data-cy="DropdownMonth"
				label="Month"
				:options="months"
				@updated="setMonth"
			/>
			<MultiSelect
				ref="intakeYearMultiSelect"
				class="IntakeMultiSelect"
				data-cy="DropdownYear"
				label="Year"
				:options="years"
				@updated="setYear"
			/>
			<p class="SMTText SMTTextSmall">
				In case of multiple intake periods, submit them separately per intake.
			</p>
		</div>
		<div class="SMTPageContentBlock">
			<img
				class="SMTPageImage"
				src="https://monet-prtl-co.imgix.net/Endor/SMT/intake-period-visual.svg"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./intake-period.ts"></script>
<style lang="scss" src="./intake-period.scss"></style>
