<template>
	<div class="UploadVerifyNote">
		Verify the format of the student email addresses with the help of this
		<a
			href="https://smt-resources.s3.eu-west-1.amazonaws.com/SMT-upload-verify-template.xlsx"
			@click="trackDownloadTemplateFileClick()"
		>Template file</a>.
	</div>
</template>

<script lang="ts" src="./upload-verify-note.ts"></script>
<style lang="scss" src="./upload-verify-note.scss"></style>
