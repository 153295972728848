import { computed, ref } from 'vue';
import { IFAQQuestionInfo, IFAQQuestionInfoCluster } from '@/models/faq-question-info.interface';
import { Routes } from '@/models/router.interface';
import store from '@/store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const faqQuestions = require('./../../../../static/data/faq-questions.json') as IFAQQuestionInfoCluster;

export default class FaqComponent {
	public contentOpened = ref(false);
	public selectedQuestion = ref(-1);

	public get questions(): IFAQQuestionInfo[] {
		return faqQuestions.data;
	}

	public get uniAdminUrlFAQ(): string {
		const uniAdminUrl = webpackDefinitions.VUE_UNI_ADMIN_URL;
		return `${uniAdminUrl}support/faq/`;
	}

	public isOnStartPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === Routes.START;
	});

	public toggleContent(): void {
		this.contentOpened.value = !this.contentOpened.value;
	}

	public updateSelectedQuestion(index: number): void {
		this.selectedQuestion.value = this.selectedQuestion.value === index ? -1 : index;
	}
}
