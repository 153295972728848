<template>
	<div id="MatchingProgressOverview">
		<h1 class="SMTTitle">
			{{ matchingStatusText }}
		</h1>
		<div class="SMTCardWrapper">
			<div :class="[
				'SMTCard',
				anonymisingInProgress ? 'Loading' : ''
			]">
				<template v-if="anonymisingFailed">
					<div class="MatchingProgressIconWrapper">
						<i class="lnr-notification-circle MatchingProgressIcon Failure"></i>
					</div>
					<p class="SMTCardText SMTTextSmall">
						Anonymisation of enrolment information failed
					</p>
				</template>
				<template v-else>
					<div
						class="MatchingProgressIconWrapper"
						data-cy="CardContentAnonymisingDataSuccess"
					>
						<i class="lnr-lock MatchingProgressIcon Success"></i>
						<img
							class="MatchingProgressCheckmark"
							src="https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
						/>
					</div>
					<p class="SMTCardText SMTTextSmall">
						Enrolment information is anonymised
					</p>
				</template>
			</div>
			<div :class="[
				'SMTCard',
				submittingInProgress ? 'Loading' : ''
			]">
				<template v-if="submittingFailed">
					<div class="MatchingProgressIconWrapper">
						<i class="lnr-warning MatchingProgressIcon Failure"></i>
					</div>
					<p class="SMTCardText SMTTextSmall">
						Submit of anonymised data to Studyportals' secure server failed
					</p>
				</template>
				<template v-else>
					<div
						class="MatchingProgressIconWrapper"
						data-cy="CardContentSubmittingDataSuccess"
					>
						<i class="lnr-university MatchingProgressIcon Success"></i>
						<img
							class="MatchingProgressCheckmark"
							src="https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
						/>
					</div>
					<p class="SMTCardText SMTTextSmall">
						Anonymised data is submitted to Studyportals' secure server
					</p>
				</template>
			</div>
			<div :class="[
				'SMTCard',
				matchingInProgress ? 'Loading' : ''
			]">
				<div
					class="MatchingProgressIconWrapper"
					data-cy="CardContentMatchingDataSuccess"
				>
					<i class="lnr-profile MatchingProgressIcon Success"></i>
					<img
						class="MatchingProgressCheckmark"
						src="https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
					/>
				</div>
				<p class="SMTCardText SMTTextSmall">
					Data is matched to similarly anonymised data
				</p>
			</div>
		</div>
	</div>
</template>

<script lang="ts" src="./matching-progress-overview.ts"></script>
<style lang="scss" src="./matching-progress-overview.scss"></style>
