<template>
	<div id="NavigationStatus">
		<template v-if="isCurrentRouteNavigationStep">
			<div
				v-for="step in steps"
				v-bind:key="step"
				:class="[
					'NavigationStatusBlock',
					isStepSelected(step) ? 'Selected' : ''
				]">
				{{ step }}
			</div>
		</template>
	</div>
</template>

<script lang="ts" src="./navigation-status.ts"></script>
<style lang="scss" src="./navigation-status.scss"></style>
