<template>
	<div id="FrequentlyAskedQuestions">
		<div v-if="isOnStartPage">
			<div class="FAQIntroduction SMTWhiteBlock">
				<img
					src="https://monet-prtl-co.imgix.net/Endor/SMT/faq-light-bulb.svg"
					class="FAQIntroductionImage"
				/>
				<p class="FAQIntroductionText">
					Below you can find a collection of frequently asked questions about the Student Matching Tool.
					If you have more questions please contact your Partnership Success Manager or email us via
					<span class="FAQIntroductionEmailAddress">client-services@studyportals.com.</span>
				</p>
				<i
					@click="toggleContent"
					:class="[
						'FAQToggleChevron',
						contentOpened ? 'lnr-chevron-down' : 'lnr-chevron-up'
					]"></i>
			</div>
			<div
				class="FAQContent"
				v-if="contentOpened"
			>
				<template v-for="(questionInfo, index) in questions">
					<div
						@click="updateSelectedQuestion(index)"
						:class="[
							'FAQQuestion',
							 'SMTWhiteBlock',
							selectedQuestion === index ? 'Selected' : ''
						]"
					>
						{{ questionInfo.question }}
					</div>
					<div class="FAQAnswer SMTWhiteBlock">
						<p
							v-for="paragraph in questionInfo.answerParagraphs"
							v-html="paragraph"
							@click.prevent="replaceUrls"
						></p>
					</div>
				</template>
			</div>
		</div>
		<p
			v-else
			class="SMTTransparentBlock"
		>
			For more information on the Student Matching Tool, visit
			<a
				:href="uniAdminUrlFAQ"
				target="uniAdminFAQ"
			>
				our FAQ page
			</a>
		</p>
	</div>
</template>

<script lang="ts" src="./faq.ts"></script>
<style lang="scss" src="./faq.scss"></style>
