<template>
	<div id="InvalidRecordsOverview">
		<ul class="InvalidRecordsList">
			<li
				v-for="record in invalidRecords"
				class="InvalidRecordDetails"
				:title="record.value"
			>
				Row {{ record.row + 1 }}: {{ record.value }}
			</li>
		</ul>
	</div>
</template>

<script lang="ts" src="./invalid-records-overview.ts"></script>
<style lang="scss" src="./invalid-records-overview.scss"></style>
