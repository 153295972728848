<template>
	<div id="UploadButtonWrapper">
		<input
			type="file"
			ref="uploadButton"
			id="UploadButton"
			class="Hidden"
			accept=".csv"
			@change="processUpload"
		/>
		<template v-if="isUploadValid">
			<label
				:class="[
					'UploadButtonLabel',
					upload.validation ? 'UploadFailed' : 'UploadSuccess'
				]"
				for="UploadButton"
			>
				<span class="UploadTitle">
					<i class="lnr-checkmark-circle UploadIcon"></i>
					<span class="UploadButtonAlongsideIconText">
						{{ upload.title }}
					</span>
				</span>
				<span class="UploadButtonActionText NextUploadText">
					Change file
				</span>
			</label>
		</template>
		<template v-else>
			<label
				class="UploadButtonLabel UploadStart"
				for="UploadButton"
			>
				<span class="UploadButtonActionText FirstUploadText">
					<i class="lnr-plus-square UploadIcon"></i>
					<span class="UploadButtonAlongsideIconText">
						Choose file
					</span>
				</span>
			</label>
		</template>
	</div>
</template>

<script lang="ts" src="./upload-button.ts"></script>
<style lang="scss" src="./upload-button.scss"></style>
