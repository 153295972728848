<template>
	<div id="StudentMatchingToolClients">
		<div class="SMTWhiteBlock">
			<NavigationStatus />
			<component :is="CurrentComponent" v-bind="router.props" class="RouterView" />
			<Navigation />
		</div>
		<FAQ />

		<LogOut v-if="standAloneLogin" />
	</div>
</template>

<script lang="ts" src="./app.ts"></script>
<style lang="scss" src="./styles/main.scss"></style>
