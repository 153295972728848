<template>
	<div
		id="PageStart"
		class="SMTPage"
	>
		<div class="SMTPageContentBlock">
			<h1 class="SMTTitle">
				Welcome to the Student Matching Tool!
			</h1>
			<p class="SMTText">
				Automatically and safely verify students
				who found your institution via Studyportals
				based on the email addresses they used.
			</p>
			<div
				v-if="isOnlyOneOrganisationAvailable"
				class="OrganisationSelectWrapper SMTText"
			>
				<p class="OrganisationSelectedText">
					Students will be matched for the following organisation:
				</p>
				<p class="SelectedOrganisationText">
					{{ organisationDetails.organisations[0].name }}
				</p>
			</div>
			<div v-else class="OrganisationSelectWrapper">
				<p class="SMTText OrganisationSelectText">
					To get started, please select the institution that the enrolments apply to:
				</p>
				<MultiSelect
					ref="organisationMultiSelect"
					class="OrganisationMultiSelect"
					data-cy="DropdownOrganisation"
					label="Organisation"
					:options="organisationOptions"
					@updated="selectOrganisation"
				/>
			</div>
		</div>
		<div class="SMTPageContentBlock">
			<img
				class="SMTPageImage"
				src="https://monet-prtl-co.imgix.net/Endor/SMT/start-visual.svg"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./start.ts"></script>
<style lang="scss" src="./start.scss"></style>
