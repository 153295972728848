<template>
	<div id="SMTNavigationWrapper">
		<button
			v-if="isStepBackAvailable"
			@click="stepBack"
			class="NavigationBackButton"
		>
			<i class="lnr-arrow-left NavigationBackButtonIcon"></i>
			Back
		</button>
		<button
			v-if="isSkipAvailable"
			@click="stepForward"
			class="ChampionButton"
		>
			Skip
		</button>
		<button
			v-else-if="areStepsAvailable"
			@click="stepForward"
			:disabled="isNextDisabled"
			class="ChampionButton"
		>
			{{ nextButtonText }}
		</button>
	</div>
</template>

<script lang="ts" src="./navigation.ts"></script>
<style lang="scss" src="./navigation.scss"></style>
