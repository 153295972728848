"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalState = void 0;
var ModalState;
(function (ModalState) {
    ModalState["OPEN"] = "open";
    ModalState["VISIBLE"] = "visible";
    ModalState["CREATED"] = "created";
    ModalState["CLOSED"] = "closed";
    ModalState["DESTROYED"] = "Destroyed";
})(ModalState = exports.ModalState || (exports.ModalState = {}));
