import { createApp, h } from 'vue';
import { IModalManager } from '@/models/modal/modal-manager.interface';
import { ModalManager } from '@studyportals/modal';
import store from '@/store';
import App from '@/presentation/app.vue';
import LogIn from '@/stand-alone/log-in/log-in.vue';
import Bootstrapper from '@/bootstrapper';
import StylesheetLoader from '@/stylesheet-loader';

const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';

declare global {
	interface Window {
		ModalManager: IModalManager;
	}
}

window.ModalManager = new ModalManager();

const url = webpackDefinitions.VUE_APP_ICONSET_URL;
StylesheetLoader.loadStylesheet(url);

store.mutations.setStandAloneLogin(standAloneLogin);

const placeholderSelector = '#StudentMatchingToolClientsPlaceholder';

Bootstrapper.bootstrap()
	.then(() => {
		const app = createApp({
			render: () => {
				return h(App);
			}
		});

		app.mount(placeholderSelector);
	})
	.catch((error: Error) => {
		if (!standAloneLogin || error.name !== 'NotAuthorizedException') {
			throw error;
		}

		const app = createApp({
			render: () => {
				return h(LogIn);
			}
		});

		app.mount(placeholderSelector);
	});
