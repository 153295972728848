import { defineComponent } from 'vue';
import FaqComponent from '@/presentation/components/faq/faq-class';

export default defineComponent({
	setup: () => {
		const component = new FaqComponent();

		return {
			contentOpened: component.contentOpened,
			selectedQuestion: component.selectedQuestion,
			questions: component.questions,
			uniAdminUrlFAQ: component.uniAdminUrlFAQ,
			isOnStartPage: component.isOnStartPage,
			toggleContent: component.toggleContent.bind(component),
			updateSelectedQuestion: component.updateSelectedQuestion.bind(component)
		};
	}
});
