<template>
	<div id="UploadOverview">
		<h1 class="SMTTitle">
			{{ pageTitle }}
		</h1>
		<p class="SMTText">
			Submit student data
			<span v-html="recruitmentStageAsText"></span>
			<span v-html="intakePeriodAsText"></span>.
			Follow the steps below to make sure that
			you prepare the file in the right format.
		</p>
		<div :class="[
			'SMTCardWrapper',
			upload ? 'ValidUploadDetails' : 'AwaitingUpload'
		]">
			<div class="SMTCard">
				<p class="SMTCardText SMTTextSmall">
					Create an Excel file that contains
					<strong>all known email addresses</strong>.
					If you have multiple emails per enrolment,
					<strong>include them all.</strong>
					<span class="SMTAttentionTag">Important!</span>
				</p>
			</div>

			<div class="SMTCard">
				<p class="SMTCardText SMTTextSmall">
					Please make sure only the first column contains information.
				</p>
			</div>

			<div class="SMTCard">
				<p class="SMTCardText SMTTextSmall">
					Save your Excel as a <strong>.CSV</strong> file.
				</p>
			</div>

			<div class="SMTCard">
				<p class="SMTCardText SMTTextSmall">
					Submit the file here
				</p>
				<UploadButton />
			</div>

			<UploadVerifyNote v-if="!upload" />
		</div>
	</div>
</template>

<script lang="ts" src="./upload-overview.ts"></script>
<style lang="scss" src="./upload-overview.scss"></style>
