<template>
	<div
		ref="modalContent"
		id="ReviewUploadModal"
	>
		<div class="ReviewUploadHeaderBlock">
			<h3>Review upload</h3>
			<span class="ReviewUploadProgressIndicator">
				{{ modalReviewUploadState }}/2
			</span>
		</div>
		<div class="ReviewUploadContentBlock">
			<template v-if="isModalStepUploadScopeAndType">
				<p class="ReviewUploadTextBlock">
					Thank you for your submission!
					Before you go, please help us
					better understand what you submitted.
				</p>

				<fieldset
					class="ReviewUploadQuestionBlock"
					data-cy="QuestionGeoScope"
				>
					<legend class="ReviewUploadTitle">
						Which type of enrolments? (Geo Scope)
					</legend>

					<div v-for="option in geoScopeOptions">
						<input
							type="radio"
							name="geoScope"
							class="ReviewUploadRadioButton"
							:id="option.id"
							:value="option.value"
							v-model="selectedGeoScope"
						/>
						<label
							:for="option.id"
							class="ReviewUploadQuestionText"
						>
							{{ option.label }}
						</label>
					</div>
				</fieldset>

				<fieldset
					class="ReviewUploadQuestionBlock"
					data-cy="QuestionCommissionType"
				>
					<legend class="ReviewUploadTitle">
						What commission type? (Agents vs. Direct)
					</legend>

					<div v-for="option in commissionTypeOptions">
						<input
							type="radio"
							name="commissionType"
							class="ReviewUploadRadioButton"
							:id="option.id"
							:value="option.value"
							v-model="selectedCommissionType"
						/>
						<label
							:for="option.id"
							class="ReviewUploadQuestionText"
						>
							{{ option.label }}
						</label>
					</div>
				</fieldset>

				<div class="ReviewUploadButtonWrapper">
					<button
						class="DriverButton ReviewUploadButton"
						@click="showUploadContentsScreen"
					>
						Next
					</button>
				</div>
			</template>
			<template v-else-if="isModalStepUploadContents">
				<div class="WarningContainer">
					<i class="lnr-info WarningIcon"></i>
					<div class="WarningMessage">
						Please confirm if you have uploaded
						all email addresses for each enrolment,
						including any additional emails.
					</div>
				</div>

				<fieldset
					class="ReviewUploadQuestionBlock"
					data-cy="QuestionFileContents"
				>
					<legend class="ReviewUploadTitle">
						This file contains:
					</legend>

					<div v-for="option in fileContentsOptions">
						<input
							type="radio"
							name="fileContents"
							class="ReviewUploadRadioButton"
							:id="option.id"
							:value="option.value"
							v-model="selectedFileContents"
						/>
						<label
							:for="option.id"
							class="ReviewUploadQuestionText"
						>
							{{ option.label }}
						</label>
					</div>
				</fieldset>

				<fieldset
					v-if="isSelectedFileContentsMultiple"
					class="ReviewUploadQuestionBlock"
					data-cy="QuestionNumberOfEnrolments"
				>
					<legend class="ReviewUploadTitle">
						Number of unique enrolments in the file:
					</legend>

					<div class="ReviewUploadInputNote">
						*If unknown, leave blank
					</div>
					<label
						class="ReviewUploadQuestionText"
						for="ReviewUploadNumberOfEnrolments"
					>
						Unique Enrolments:
					</label>
					<input
						type="number"
						id="ReviewUploadNumberOfEnrolments"
						class="ReviewUploadNumberInput"
						placeholder="Insert number"
						v-model="numberOfUniqueEnrolments"
					>
				</fieldset>

				<div class="ReviewUploadButtonWrapper">
					<button
						class="NavigationBackButton"
						@click="showUploadScopeAndTypeScreen"
					>
						<i class="lnr-arrow-left NavigationBackButtonIcon"></i>
						Previous Question
					</button>
					<button
						class="DriverButton ReviewUploadButton"
						@click="closeModal"
					>
						Next
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<script lang="ts" src="./review-upload-modal.ts"></script>
<style lang="scss" src="./review-upload-modal.scss"></style>
