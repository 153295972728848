<template>
	<div
		id="PageRecruitmentStage"
		class="SMTPage"
	>
		<h1 class="SMTTitle">
			Recruitment stage
		</h1>
		<p class="SMTText">
			Select the stage of the students to match
		</p>
		<div class="SMTCardWrapper">
			<div
				v-for="stage in stages"
				@click="setStage(stage.type)"
				:class="[
					'SMTCard',
					isStageSelected(stage.type) ? 'Selected' : ''
				]"
			>
				<img
					class="SMTCardImage"
					:src="stage.image"
				/>
				<p class="SMTCardTitle">
					{{ stage.title }}
				</p>
				<p class="SMTCardText SMTTextSmall">
					{{ stage.description }}
				</p>
			</div>
		</div>
		<p class="SMTText SMTTextSmall SMTTextNote">
			In case you want to submit students from multiple recruitment stages,
			you will need to submit them separately per recruitment stage.
		</p>
	</div>
</template>

<script lang="ts" src="./recruitment-stage.ts"></script>
<style lang="scss" src="./recruitment-stage.scss"></style>
