<template>
	<div id="UploadFailedOverview">
		<h1 class="SMTTitle">
			Invalid file
		</h1>
		<div class="SMTCardWrapper InvalidUploadDetails">
			<div class="SMTCard">
				<i class="lnr-notification-circle InvalidUploadDetailsIcon"></i>
				<p
					class="SMTCardText SMTTextSmall"
					v-html="validationMessage"
				></p>
			</div>

			<div
				v-if="areInvalidRecordsAvailable"
				class="SMTCard SMTCardInvalidRecords"
			>
				<InvalidRecordsOverview />
			</div>

			<div class="SMTCard">
				<i class="lnr-redo2 InvalidUploadDetailsIcon"></i>
				<p class="SMTCardText SMTTextSmall">
					Submit it here
				</p>
				<UploadButton />
			</div>

			<UploadVerifyNote />
		</div>
	</div>
</template>

<script lang="ts" src="./upload-failed-overview.ts"></script>
<style lang="scss" src="./upload-failed-overview.scss"></style>
