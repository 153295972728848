<template>
	<div
		id="PageMatching"
		class="SMTPage"
	>
		<template v-if="submitUploadNotFinishedYet">
			<MatchesProgressOverview v-if="submitUploadInProgress" />
			<template v-else>
				<div class="SMTPageContentBlock">
					<h1 class="SMTTitle">Match</h1>
					<p class="SMTText">
						You are about to anonymise and match
						<span v-html="recruitmentStageAsText"></span><span v-html="intakePeriodAsText"></span>.
					</p>
					<p class="SMTText SMTAdditionalText">
						Before sending the data to the Studyportals servers,
						it is irreversibly anonymised according to the General Data
						Protection Regulation (GDPR) and our mutual Data Processing Agreement (DPA).
					</p>
				</div>
				<div class="SMTPageContentBlock">
					<img
						class="SMTPageImage"
						src="https://monet-prtl-co.imgix.net/Endor/SMT/matching-visual.svg"
					/>
				</div>
			</template>
		</template>
		<ReviewUploadModal v-if="shouldModalBeShown" />
	</div>
</template>

<script lang="ts" src="./matching.ts"></script>
<style lang="scss" src="./matching.scss"></style>
