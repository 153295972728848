<template>
	<div
		id="PageUpload"
		class="SMTPage"
	>
		<UploadFailedOverview v-if="isUploadInvalid" />
		<UploadOverview v-else />
	</div>
</template>

<script lang="ts" src="./upload.ts"></script>
<style lang="scss" src="./upload.scss"></style>
