<template>
	<div
		id="PageEnd"
		class="SMTPage"
	>
		<div class="SMTPageContentBlock">
			<template v-if="matchesFound">
				<h1 class="SMTTitle">Thank you!</h1>
				<p class="SMTText">
					We appreciate the time you took to share
					<span v-html="recruitmentStageAsText"></span><span v-html="intakePeriodAsText"></span>.
					Your Partnership Success Manager will be in touch with you about
					the matches as soon as the internal review is done.
				</p>
				<div class="WarningContainer">
					<i class="lnr-info WarningIcon"></i>
					<div class="WarningMessage">
						Please don't forget to upload secondary emails of your students.
					</div>
				</div>
				<p class="SMTText">
					Do you want to submit one more file?
				</p>
			</template>
			<template v-else-if="noMatchesFound">
				<h1 class="SMTTitle">
					No matches found
				</h1>
				<p class="SMTText">
					We appreciate the time you took to share
					<span v-html="recruitmentStageAsText"></span><span v-html="intakePeriodAsText"></span>.
					Unfortunately no matches were found. Please check the file
					that you submitted to verify that this outcome is correct.
				</p>
			</template>
			<template v-else-if="submitFailed">
				<h1 class="SMTTitle">
					Oops! Submit failed!
				</h1>
				<p class="SMTText">
					Something went wrong with submitting your file. Please try again.
					If the problem persists, please contact your Partnership Success Manager.
				</p>
			</template>
			<button
				class="ChampionButton"
				@click="refreshTool"
			>
				Start matching again!
			</button>
		</div>
		<div class="SMTPageContentBlock">
			<img
				v-if="matchesFound"
				class="SMTPageImage"
				src="https://monet-prtl-co.imgix.net/Endor/SMT/final-screen-visual.svg"
			/>
			<img
				v-else-if="noMatchesFound"
				class="SMTPageImage NoMatch"
				src="https://monet-prtl-co.imgix.net/Endor/SMT/no-matches-found.svg"
			/>
			<img
				v-else
				class="SMTPageImage SubmitFailed"
				src="https://monet-prtl-co.imgix.net/Endor/SMT/file-submit-failed.svg"
			/>
		</div>
	</div>
</template>

<script lang="ts" src="./end.ts"></script>
<style lang="scss" src="./end.scss"></style>
